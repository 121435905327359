  /* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap'); */

/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */
/**/

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    font-family: 'Inter', sans-serif;
    background: #fafafa;
}
